import { ApiParams } from '@/core/http/ApiResource';
import { User, UserResource } from '@/modules/user/User';
import PaginatedResponse from '@/core/http/PaginatedResponse';

class PartnerUserResource extends UserResource {
	private readonly partnerId: number | string;

	constructor(partnerId: number | string) {
		super('/api/user');

		this.partnerId = partnerId;
	}

	async getAll(params: ApiParams = {}, withLoader: boolean = this.withLoader): Promise<PaginatedResponse<User>> {
		return this.getAllRequest(`/api/partner/${this.partnerId}/user`, params, withLoader);
	}

	post(data: Partial<User>, withLoader: boolean = this.withLoader): Promise<User> {
		return this.getApi(withLoader)
			.post<User>(`/api/partner/${this.partnerId}/user`, data)
		;
	}
}

export const usePartnerUser = (partnerId: number | string) => new PartnerUserResource(partnerId);
