import store from '@/store';
import { Api } from '@/core/http/Api';

export interface Market {
	id: number;
	name: string;
	readonly language: string;
	readonly created_time: string;
}

const api = new Api();

export const getAllMarkets = async (): Promise<Market[]> => {
	if (typeof store.state.markets !== 'undefined') {
		return store.state.markets;
	}

	const response = await api.get<Market[]>('/api/parameters/market');

	store.commit('setMarkets', response);

	return response;
}
