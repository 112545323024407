import ApiResource from '@/core/http/ApiResource';

export interface CompanyRecord {
	name: string;
	address: string;
	zip: string;
	city: string;
	phone: string;
}

export const useCompanyRecord = () => {
	const api = new ApiResource<CompanyRecord>('/api/company_record');

	return {
		search(identityNumber: string): Promise<CompanyRecord> {
			const url = api.applyParamsToUrl('', {
				search: identityNumber,
			});

			return api.request('GET', url);
		},
	};
};
