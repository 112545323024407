import { onBeforeMount, Ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { handleLoadError } from '@/core/common';
import { Partner, usePartner } from '@/modules/partner/Partner';
import { usePartnerUser } from '@/modules/partner/PartnerUser';

export const usePartnerRouteLoader = (resource: Ref, partner: Ref<Partner>) => {
	const route = useRoute();

	const load = async () => {
		if (!route.params.partner_id) {
			return;
		}

		try {
			partner.value = await usePartner().get(route.params.partner_id as string);
			resource.value = usePartnerUser(partner.value?.id);
		} catch (e) {
			handleLoadError(e as Error, 'partner.index');
		}
	};

	watch(route, load);
	onBeforeMount(load);
};
